window.google = window.google || {};

google.productlistingevents = {
  constants: {
    productListItemClass: '.tag-manager-product-list-item',
    productSliderClass: '.tag-manager-product-slider-item',
    readMoreClass: '.read-more-button',
  },

  setup: function () {
    try {
      google.productlistingevents.addOnListItemClickEventToViewProduct();
      google.productlistingevents.addOnSliderClickEventToViewProduct();
      google.productlistingevents.addOnClickEventToReadMore();
    } catch (error) {}
  },

  addOnListItemClickEventToViewProduct: function () {
    try {
      document.querySelectorAll(google.productlistingevents.constants.productListItemClass).forEach((item) => {
        item.addEventListener('click', (event) => {
          google.productlistingevents.addClickedProductsToDataLayer(event.target);
        });
      });
    } catch (error) {}
  },

  addOnSliderClickEventToViewProduct: function () {
    try {
      document.querySelectorAll(google.productlistingevents.constants.productSliderClass).forEach((item) => {
        item.addEventListener('click', (event) => {
          google.productlistingevents.addClickedProductsToDataLayer(event.target);
        });
      });
    } catch (error) {}
  },

  addClickedProductsToDataLayer: function (item) {
    try {
      google.tagmanager.events.clickOnProduct(item);
    } catch (error) {}
  },

  selectFilter: function (filterTypeEnglishName, filterSelectedEnglishName) {
    try {
      google.tagmanager.add({
        event: 'filterSelection',
        filterType: filterTypeEnglishName,
        selectedFilter: filterSelectedEnglishName,
      });
    } catch (error) {}
  },

  addOnClickEventToReadMore: function () {
    try {
      document.querySelectorAll(google.productlistingevents.constants.readMoreClass).forEach((item) => {
        item.addEventListener('click', (event) => {
          google.tagmanager.events.clickOnReadMore(event.target);
        });
      });
    } catch (error) {}
  },

  init: function () {
    try {
      google.productlistingevents.setup();
    } catch (error) {}
  },
};

if (document.readyState === 'complete' || document.readyState === 'loaded') {
  google.productlistingevents.init();
} else {
  window.addEventListener('DOMContentLoaded', () => {
    google.productlistingevents.init();
  });
}
